<template>
  <div class="content">
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
        <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Form Transfer Intra</h6>
        </div>
      </div>

      <b-overlay :show="loading">
        <div class="form-underlying card-body p-3">
          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>S</h2>
                  </div>
                  <strong class="ml-2">Situation</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra1">
              <table class="table table-bordered">
                <tr>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Nama</h4>
                      <p>{{row.ap_fullname||"-"}}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>No. Rekam Medis</h4>
                      <p>{{row.ap_code||"-"}}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>NIK</h4>
                      <p>{{row.ap_nik||"-"}}</p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal Lahir</h4>
                      <p v-if="row.ap_dob">{{row.ap_dob | moment("DD MMMM YYYY")}}</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>DPJP</h4>
                      <p>{{row.dpjp_name||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal Datang</h4>
                      {{ row.artipr_tanggal_datang | moment('DD MMMM YYYY, HH:mm') }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Diagnosa</h4>
                      <p>{{row.artipr_diagnosa||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Kondisi Saat Ini</h4>
                      {{ row.artipr_kondisi_saat_ini }}
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4></h4>
                      <p></p>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div class="result_tab">
                    <h4>Dari Ruang</h4>
                    <p>{{row.ruang_dari||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Ke Ruang</h4>
                      <p>{{row.ruang_ke||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal Pindah</h4>
                      {{ row.artipr_tanggal_pindah | moment('DD MMMM YYYY, HH:mm')}}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                      <h2>B</h2>
                  </div>
                  <strong class="ml-2">Background</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra2">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for=""><strong>Keluhan Utama</strong></label>
                        <p>{{ row.artipr_keluhan_utama }}</p>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for=""><strong>Riwayat Penyakit</strong></label>
                        <p>{{ row.artipr_riwayat_penyakit }}</p>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for=""><strong>Intervensi yang Telah Dilakukan</strong></label>
                        <p>{{ row.artipr_background_intervensi }}</p>
                      </div>  
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="asuhanPembedahan">Riwayat Pembedahan </label>
                        <P>{{ row.artipr_riwayat_pembedahan }}</P>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for=""><strong>Obat-obatan yang Diberikan</strong></label>
                        <p>{{ row.artipr_obat_obatan_yang_diberikan }}</p>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for=""><strong>Cairan Parenteral yang Diberikan</strong></label>
                        <p>{{ row.artipr_cairan_parental_yang_diberikan }}</p>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for=""><strong>Riwayat Alergi</strong></label>
                        <p>{{ row.artipr_riwayat_alergi }}</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for=""><strong>Tindakan Invasif</strong></label>
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th width="48">#</th>
                          <th>Tindakan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (row.artipr_tindakan_invasif||[])" :key="k">
                          <td>{{k+1}}</td>
                          <td>
                            {{ row.artipr_tindakan_invasif[k] }}
                          </td>
                        </tr>
                        <tr v-if="!(row.artipr_tindakan_invasif||[]).length">
                          <td colspan="3" class="text-center">
                              Tidak ada Data
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>A</h2>
                  </div>
                  <strong class="ml-2">Assessment</strong>
                </div>
              </a>
            </div>

            <div class="wrap_line collapse show" id="formIntra3">
              <div class="card border shadow-0">
                <div class="bg-light card-header py-2">
                  <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                </div>
                <div class="card-body">
                  <table class="table table-bordered">
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Skala Nyeri</h4>
                          <p>{{row.artipr_skala_nyeri_value||0}} 
                            <span v-if="row.artipr_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                            <span v-else-if="row.artipr_skala_nyeri_value >= 1 && row.artipr_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                            <span v-else-if="row.artipr_skala_nyeri_value >= 4 && row.artipr_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                            <br />(Numeric Rating Scale)
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tingkat Kesadaran</h4>
                          <p>
                            {{ row.artipr_tingkat_kesadaran_value }}
                            <template v-if="row.umur_hari <= 28">
                              <span v-if="row.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 12 && row.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 10 && row.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 7 && row.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 5 && row.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 4 && row.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                              <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                            </template>
                            <template v-else-if="row.umur_hari > 28">
                              <span v-if="row.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 12 && row.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 10 && row.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 7 && row.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 5 && row.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                              <span v-else-if="row.artipr_tingkat_kesadaran_value >= 4 && row.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                              <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                            </template>
                            <br />(GCS)
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Risiko Jatuh</h4>
                          <p>{{row.artipr_resiko_jatuh_value||0}} 
                            <span v-if="row.artipr_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                            <span v-else-if="row.artipr_resiko_jatuh_value >= 7 && row.artipr_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                            <span v-else-if="row.artipr_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                            <br />
                            <span v-if="row.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                            <span v-else-if="row.ap_usia > 18 && row.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                            <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tekanan Darah</h4>
                          <p>{{row.artipr_tekanan_darah_min||"-"}}/{{row.artipr_tekanan_darah_max||"-"}} mmHG</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Nadi</h4>
                          <p>{{row.artipr_nadi||"-"}}x per menit</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Suhu</h4>
                          <p>{{row.artipr_suhu||"-"}} &deg;C</p>
                          </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Respiration Rate</h4>
                          <p>{{row.artipr_respiration||"-"}}x per menit</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>SpO2</h4>
                          <p>{{row.artipr_spo2||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4></h4>
                          <p></p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                      <label for=""><strong>Pemeriksaan Lab</strong></label>
                      <p>{{ row.artipr_lab }}</p>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                      <label for=""><strong>Radiologi</strong></label>
                      <p>{{ row.artipr_radiologi }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-header p-0" role="tab">
              <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                <div class="d-flex align-items-center">
                  <div class="label_code m-0">
                    <h2>R</h2>
                  </div>
                  <strong class="ml-2">Recommendation</strong>
                </div>
              </a>
            </div>
            <div class="wrap_line collapse show" id="formIntra4">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for=""><strong>Tindakan yang Perlu Dilanjutkan</strong></label>
                    <p>{{ row.artipr_tindakan }}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for=""><strong>Usulan Kolaborasi</strong></label>
                    <p>{{ row.artipr_usulan_kolab }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2">
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <label><strong>Penandatangan 1</strong></label>
                  <p>{{ row.artipr_ttd_name_1 }}</p>
                </b-form-group>
                <div class="form-group">
                  <img :src="row.artipr_ttd_1" />
                </div>
              </b-col>
              <b-col md="4">
                <label><strong>Penandatangan 2</strong></label>
                <b-form-group>
                  <p>{{ row.artipr_ttd_name_2 }}</p>
                </b-form-group>
                <div class="form-group">
                  <img :src="row.artipr_ttd_2" />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row class="mt-3">
            <b-col md=12 class="text-right">
              <b-button type="button" class="mr-2" variant="secondary" @click="$router.back()">Kembali</b-button>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </div>
    
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'
const _ = global._

export default {
  data(){
    return {
      row: {},
      loading: false,
    }
  },
  mounted(){
    this.getForm()
  },
  methods: {
    getForm(){
      this.loading = true

      Gen.apiRest("/get/" + this.$route.name + '/form-ttd', {
        params: { id: this.$route.params.id }
      }).then(res => {
        console.log(res.data.row)
        this.$set(this, 'row', res.data.row)
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$swal({
          icon: 'error',
          text: err.response.data.message || 'Terjadi suatu kesalahan!'
        })
      })
    },
  },
}
</script>