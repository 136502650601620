<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <h5 class="card-title font-weight-semibold">Transfer Intra Rumah Sakit dari Kamar Bedah</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered">
                        <tbody>
                          <tr>
                            <td class="table-warning">(S)<br>SITUATION</td>
                            <td>
                              <table class="table  table-sm  table-bordered">
                                <tbody>
                                  <tr>
                                    <td style="width:50%">
                                      <div class="result_tab">
                                        <h4>Dokter Operator</h4>
                                        <p>{{row.dokter_operator||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Dokter Anestesi</h4>
                                        <p>{{row.dokter_anestesi||"-"}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="width:50%">
                                      <div class="result_tab">
                                        <h4>Diagnosa pra Operasi</h4>
                                        <p>{{row.arano_diagnosa||"-"}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Tanggal tindakan operasi</h4>
                                        <p>{{row.arano_tanggal_operasi_start | moment("DD MMMM YYYY")}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="width:50%">
                                      <div class="result_tab">
                                        <h4>Waktu Mulai Operasi</h4>
                                        <p>{{row.arano_tanggal_operasi_start | moment("DD MMMM YYYY")}}, {{row.arano_jam_operasi_start}}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Waktu Selesai Operasi</h4>
                                        <p>{{row.arano_tanggal_operasi_end | moment("DD MMMM YYYY")}}, {{row.arano_jam_operasi_end}}</p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="width:50%">
                                      <div class="result_tab">
                                        <h4>Jenis Anetesi</h4>
                                        <p>{{row.arano_jenis_new||row.arano_jenis||"-"}} </p>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="result_tab">
                                        <h4>Diagnosa pasca operasi <small class="txt_mandatory">*</small></h4>
                                        <p><b-form-textarea v-model="row.arantbr_diagnosa_pasca_operasi" class="form-control" rows="2"></b-form-textarea></p>
                                        <VValidate 
                                          name="Diagnosa pasca operasi" 
                                          v-model="row.arantbr_diagnosa_pasca_operasi" 
                                          :rules="toValidate(mrValidation.arantbr_diagnosa_pasca_operasi)"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="2">
                                      <div class="result_tab">
                                        <h4>Keluhan utama saat ini <small class="txt_mandatory">*</small></h4>
                                        <p><b-form-textarea v-model="row.arantbr_keluhan_utama" class="form-control" rows="2"></b-form-textarea></p>
                                        <VValidate 
                                          name="Keluhan utama saat ini" 
                                          v-model="row.arantbr_keluhan_utama" 
                                          :rules="toValidate(mrValidation.arantbr_keluhan_utama)"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td class="table-info">(B)<br>BACKGROUND</td>
                            <td>
                              <table class="table  table-sm mb-2  table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Kelengkapan status pasien</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b-form-checkbox-group
                                        id="arantbr_kelengkapan_status_pasien"
                                        v-model="row.arantbr_kelengkapan_status_pasien"
                                        :options="Config.mr.mRanapUBSKelengkapan"
                                        name="arantbr_kelengkapan_status_pasien"
                                      ></b-form-checkbox-group>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>


                              <table class="table  table-sm mb-2  table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Pemeriksaan jaringan</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b-form-checkbox-group
                                        id="arantbr_pemeriksaan_jaringan"
                                        v-model="row.arantbr_pemeriksaan_jaringan"
                                        name="arantbr_pemeriksaan_jaringan"
                                      >
                                        <b-form-checkbox value="PA">PA</b-form-checkbox>
                                        <b-form-checkbox value="Kultur">Kultur</b-form-checkbox>
                                      </b-form-checkbox-group>
                                    </td>

                                    <td>
                                      <div class="form-group">
                                        <label>Infus yang Terpasang</label>
                                        <p><b-form-textarea v-model="row.arantbr_infus_yang_terpasang" class="form-control" rows="2"></b-form-textarea></p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <table class="table   table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th>Alat medis yang terpasang </th>
                                    <th>Tanggal Pasang </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <b-form-checkbox value="Y" unchecked-value="N"
                                        v-model="row.arantbr_ngt_no"
                                        :name="'arantbr_ngt_no'"
                                        class="form-check-input-styled c-flex">
                                        NGT no
                                        
                                        <div v-if="row.arantbr_ngt_no == 'Y'">
                                          <b-form-input v-model="row.arantbr_ngt_no_text" type="text" class="form-control ml-2" />      
                                          <VValidate 
                                            name="NGT no" 
                                            v-model="row.arantbr_ngt_no_text" 
                                            :rules="toValidate(mrValidation.arantbr_ngt_no_text)"
                                          />
                                        </div>
                                      </b-form-checkbox>
                                    </td>

                                    <td>
                                        <div class="input-group col-md-10">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                                              calendar-class="my-datepicker"
                                              v-model="row.arantbr_ngt_no_date">
                                          </datepicker>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantbr_ngt_no_jam">
                                          </vue-timepicker>
                                        </div>
                                        
                                        <VValidate 
                                          v-if="row.arantbr_ngt_no == 'Y'"
                                          name="Tanggal Pasang NGT No" 
                                          v-model="row.arantbr_ngt_no_date" 
                                          :rules="{required:1}"
                                        />
                                        
                                        <VValidate 
                                          v-if="row.arantbr_ngt_no == 'Y'"
                                          name="Jam Pasang NGT No" 
                                          v-model="row.arantbr_ngt_no_jam" 
                                          :rules="{required:1}"
                                        />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox value="Y" unchecked-value="N"
                                        v-model="row.arantbr_foley_no"
                                        :name="'arantbr_foley_no'"
                                        class="form-check-input-styled c-flex">
                                        Foley no
                                        
                                        <div v-if="row.arantbr_foley_no == 'Y'">
                                          <b-form-input v-model="row.arantbr_foley_no_text" type="text" class="form-control ml-2" />      <VValidate 
                                            name="Foley no" 
                                            v-model="row.arantbr_foley_no_text" 
                                            :rules="toValidate(mrValidation.arantbr_foley_no_text)"
                                          />
                                        </div>
                                      </b-form-checkbox>
                                    </td>

                                    <td>
                                        <div class="input-group col-md-10">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                                              calendar-class="my-datepicker"
                                              v-model="row.arantbr_foley_no_date">
                                          </datepicker>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantbr_foley_no_jam">
                                          </vue-timepicker>
                                        </div>
                                        
                                        <VValidate 
                                          v-if="row.arantbr_foley_no == 'Y'"
                                          name="Tanggal Pasang Foley No" 
                                          v-model="row.arantbr_foley_no_date" 
                                          :rules="{required:1}"
                                        />
                                        
                                        <VValidate 
                                          v-if="row.arantbr_foley_no == 'Y'"
                                          name="Jam Pasang Foley No" 
                                          v-model="row.arantbr_foley_no_jam" 
                                          :rules="{required:1}"
                                        />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b-form-checkbox value="Y" unchecked-value="N"
                                        v-model="row.arantbr_ett_no"
                                        :name="'arantbr_ett_no'"
                                        class="form-check-input-styled c-flex">
                                        ETT no
                                        <div v-if="row.arantbr_ett_no == 'Y'">
                                          <b-form-input v-model="row.arantbr_ett_no_text" type="text" class="form-control ml-2" />      <VValidate 
                                            name="ETT no" 
                                            v-model="row.arantbr_ett_no_text" 
                                            :rules="toValidate(mrValidation.arantbr_ett_no_text)"
                                          />
                                        </div>
                                      </b-form-checkbox>
                                    </td>

                                    <td>
                                        <div class="input-group col-md-10">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                                              calendar-class="my-datepicker"
                                              v-model="row.arantbr_ett_no_date">
                                          </datepicker>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantbr_ett_no_jam">
                                          </vue-timepicker>
                                        </div>
                                        
                                        <VValidate 
                                          v-if="row.arantbr_ett_no == 'Y'"
                                          name="Tanggal Pasang ETT No" 
                                          v-model="row.arantbr_ett_no_date" 
                                          :rules="{required:1}"
                                        />
                                        
                                        <VValidate 
                                          v-if="row.arantbr_ett_no == 'Y'"
                                          name="Jam Pasang ETT No" 
                                          v-model="row.arantbr_ett_no_jam" 
                                          :rules="{required:1}"
                                        />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox value="Y" unchecked-value="N"
                                        v-model="row.arantbr_drain"
                                        :name="'arantbr_drain'"
                                        class="form-check-input-styled">
                                        Drain
                                        <div v-if="row.arantbr_drain == 'Y'">
                                          <b-form-input v-model="row.arantbr_drain_text" type="text" class="form-control ml-2" />      <VValidate 
                                            name="Drain" 
                                            v-model="row.arantbr_drain_text" 
                                            :rules="toValidate(mrValidation.arantbr_drain_text)"
                                          />
                                        </div>
                                      </b-form-checkbox>
                                    </td>

                                    <td>
                                      <div class="input-group col-md-10">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                        </div>
                                        <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                                            calendar-class="my-datepicker"
                                            v-model="row.arantbr_drain_date">
                                        </datepicker>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantbr_drain_jam">
                                        </vue-timepicker>
                                      </div>
                                      
                                      <VValidate 
                                        v-if="row.arantbr_drain == 'Y'"
                                        name="Tanggal Pasang Drain" 
                                        v-model="row.arantbr_drain_date" 
                                        :rules="{required:1}"
                                      />
                                      
                                      <VValidate 
                                        v-if="row.arantbr_drain == 'Y'"
                                        name="Jam Pasang Drain" 
                                        v-model="row.arantbr_drain_jam" 
                                        :rules="{required:1}"
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox value="Y" unchecked-value="N"
                                        v-model="row.arantbr_tampon"
                                        :name="'arantbr_tampon'"
                                        class="form-check-input-styled">
                                        Tampon
                                        
                                        <div v-if="row.arantbr_tampon == 'Y'">
                                          <b-form-input v-model="row.arantbr_tampon_text" type="text" class="form-control ml-2" />      
                                          <VValidate 
                                            name="Tampon" 
                                            v-model="row.arantbr_tampon_text" 
                                            :rules="toValidate(mrValidation.arantbr_tampon_text)"
                                          />
                                        </div>

                                      </b-form-checkbox>
                                    </td>

                                    <td>
                                      <div class="input-group col-md-10">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                        </div>
                                        <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                                            calendar-class="my-datepicker"
                                            v-model="row.arantbr_tampon_date">
                                        </datepicker>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantbr_tampon_jam">
                                        </vue-timepicker>
                                      </div>
                                      
                                      <VValidate 
                                        v-if="row.arantbr_tampon == 'Y'"
                                        name="Tanggal Pasang Tampon" 
                                        v-model="row.arantbr_tampon_date" 
                                        :rules="{required:1}"
                                      />
                                      
                                      <VValidate 
                                        v-if="row.arantbr_tampon == 'Y'"
                                        name="Jam Pasang Tampon" 
                                        v-model="row.arantbr_tampon_jam" 
                                        :rules="{required:1}"
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <b-form-checkbox value="Y" unchecked-value="N"
                                        v-model="row.arantbr_lainnya_no"
                                        :name="'arantbr_lainnya_no'"
                                        class="form-check-input-styled c-flex">
                                        Lain-lain
                                        <div v-if="row.arantbr_lainnya_no == 'Y'">
                                          <b-form-input v-model="row.arantbr_lainnya_no_text" type="text" class="form-control ml-2" />      
                                          <VValidate 
                                            name="Lain-lain" 
                                            v-model="row.arantbr_lainnya_no_text" 
                                            :rules="toValidate(mrValidation.arantbr_lainnya_no_text)"
                                          />
                                        </div>
                                      </b-form-checkbox>
                                      
                                    </td>

                                    <td>
                                        <div class="input-group col-md-10">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                          </div>
                                          <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                                              calendar-class="my-datepicker"
                                              v-model="row.arantbr_lainnya_no_date">
                                          </datepicker>
                                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arantbr_lainnya_no_jam">
                                          </vue-timepicker>
                                        </div>
                                        
                                        <VValidate 
                                          v-if="row.arantbr_lainnya_no == 'Y'"
                                          name="Tanggal Pasang Lain-lain" 
                                          v-model="row.arantbr_lainnya_no_date" 
                                          :rules="{required:1}"
                                        />
                                        
                                        <VValidate 
                                          v-if="row.arantbr_lainnya_no == 'Y'"
                                          name="Jam Pasang Lain-lain" 
                                          v-model="row.arantbr_lainnya_no_jam" 
                                          :rules="{required:1}"
                                        />
                                    </td>
                                  </tr>
                                </tbody>

                              </table>

                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Terapi yang diberikan </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantbr_terapi||[])" :key="k+'Terapi'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantbr_terapi[k]" type="text" placeholder="Tindakan Terapi " 
                                        class="form-control form-control-sm" />
                                        
                                      <VValidate 
                                        :name="`Tindakan Terapi #${(k+1)}`" 
                                        v-model="row.arantbr_terapi[k]" 
                                        :rules="{required:1, min :3, max:128}"
                                      />
                                    </td>

                                    <td>
                                      <a href="javascript:;"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i
                                          class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantbr_terapi.push('')" class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>

                              <div class="form-group">
                                <label>Rencana Diit</label>
                                <p><b-form-textarea v-model="row.arantbr_rencana_diit" class="form-control" rows="2"></b-form-textarea></p>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="table-success">(A)<br>ASSESSMENT</td>
                            <td>
                              <div class="card mb-2 mt-2">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="row">
                                    <div class="col-md-5">
                                      <div class="form-group">
                                        <label>Tekanan Darah<small class="txt_mandatory">*</small></label>
                                        <div class="input-group">
                                          <b-form-input :formatter="number"
                                          v-model="row.arantbr_tekanan_darah_min" type="text" name="name"
                                          class="form-control" placeholder="Systole" />
                                          <div class="input-group-append input-group-prepend">
                                          <span class="input-group-text">/</span>
                                          </div>
                                          <b-form-input :formatter="number" placeholder="Diastole"
                                          v-model="row.arantbr_tekanan_darah_max" type="text"
                                          class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label>Nadi<small class="txt_mandatory">*</small></label>
                                        <div class="input-group">
                                          <b-form-input :formatter="number"
                                          v-model="row.arantbr_nadi" type="text" name="name"
                                          class="form-control" placeholder="Nadi" />
                                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                          <div class="input-group-append">
                                            <div style="width: 150px;">
                                              <v-select placeholder="Pilih Label" v-model="row.arantbr_label"
                                              :options="Config.mr.StatusRegular" label="text"
                                              :clearable="true" :reduce="v=>v.value"></v-select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label>Kesadaran<small class="txt_mandatory">*</small></label>
                                        <v-select placeholder="-- Pilih Salah Satu --" v-model="row.arantbr_kesadaran"
                                        :options="mKesadaran" label="text"
                                        :clearable="true" :reduce="v=>v.value"></v-select>
                                      </div>
                                    </div>

                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label>GDA<small class="txt_mandatory">*</small></label>
                                        <div class="form-row">
                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="alphanum"
                                              v-model="row.arantbr_gda" type="text" name="name"
                                              class="form-control" placeholder="GDA" />
                                              <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label>Pernafasan<small class="txt_mandatory">*</small></label>
                                        <div class="input-group">
                                          <b-form-input :formatter="number"
                                          v-model="row.arantbr_pernafasan" type="text" name="name"
                                          class="form-control" placeholder="GDA" />
                                          <div class="input-group-append"><span class="input-group-text">x/mnt</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label>SPO2<small class="txt_mandatory">*</small></label>
                                        <div class="form-row">
                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="number"
                                              v-model="row.arantbr_spo2" type="text" name="name"
                                              class="form-control" placeholder="SPO2" />
                                              <div class="input-group-append"><span class="input-group-text">%</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-3">
                                      <div class="form-group">
                                        <label>Suhu<small class="txt_mandatory">*</small></label>
                                        <div class="form-row">
                                          <div class="col-md-12">
                                            <div class="input-group">
                                              <b-form-input :formatter="number"
                                              v-model="row.arantbr_suhu" type="text" name="name"
                                              class="form-control" placeholder="Suhu" />
                                              <div class="input-group-append"><span class="input-group-text">C</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label>Perdarahan<small class="txt_mandatory">*</small></label>
                                        <div class="input-group">
                                        <b-form-input :formatter="number"
                                          v-model="row.arantbr_pendarahan" type="text" name="name"
                                          class="form-control" placeholder="Pendarahan" />
                                          <div class="input-group-append"><span class="input-group-text">CC</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td class="table-danger">(R)<br>RECOMMENDATION</td>
                            <td>
                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Tindakan Lanjutan </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantbr_tindakan_lanjutan||[])" :key="k+'Lanjutan'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantbr_tindakan_lanjutan[k]" type="text" placeholder="Tindakan Lanjutan " 
                                        class="form-control form-control-sm" />
                                        
                                      <VValidate 
                                        :name="`Tindakan Lanjutan #${(k+1)}`" 
                                        v-model="row.arantbr_tindakan_lanjutan[k]" 
                                        :rules="{required:1, min :3, max:128}"
                                      />
                                    </td>

                                    <td>
                                      <a href="javascript:;"
                                        @click="row.arantbr_tindakan_lanjutan.splice(k,1)"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i
                                          class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantbr_tindakan_lanjutan.push('')" class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>

                              <table class="table table-input  table-sm mb-2 table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="2">Hal-hal yang perlu diperhatikan </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arantbr_hal_diperhatikan||[])" :key="k+'Hal'">
                                    <td class="input-cell">
                                      <b-form-input v-model="row.arantbr_hal_diperhatikan[k]" type="text" placeholder="Hal yang diperhatikan " 
                                        class="form-control form-control-sm" />
                                        
                                      <VValidate 
                                        :name="`Hal yang diperhatikan #${(k+1)}`" 
                                        v-model="row.arantbr_hal_diperhatikan[k]" 
                                        :rules="{required:1, min :3, max:128}"
                                      />
                                    </td>

                                    <td>
                                      <a href="javascript:;"
                                        @click="row.arantbr_hal_diperhatikan.splice(k,1)"
                                        class="btn btn-sm alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                        v-b-tooltip.hover title="Delete"><i
                                          class="icon-trash"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td class="text-center" colspan="3">
                                      <a href="javascript:;" @click="row.arantbr_hal_diperhatikan.push('')" class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                      </a>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapTransferBedahRanap',
      mKesadaran: [
        {value: "Sadar", text: "Sadar"},
        {value: "Tidak Sadar", text: "Tidak Sadar"}
      ],
      
      patientData: {},
      loading: {
          patientInfo: false,
      },
    }
  },
  components:{ Datepicker,VueTimepicker,PatientInfo },
  mounted() {
    this.apiGet() 
    this.getPatientInfo()

  },
  methods: {

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.id
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({ name: 'RanapSerahTerimaPasien', params: {pageSlug: this.$route.params.pageSlug}}).catch(()=>{})   
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })
    },
    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug  
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              data.arantbr_from_cppt = 'Y'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapSerahTerimaPasien', params: {pageSlug: this.$route.params.pageSlug}}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    }
  }
}
</script>