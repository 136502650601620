<template>
  <div class="content">
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
        <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Form Transfer Intra</h6>
        </div>
      </div>

      <validation-observer ref="VForm" v-slot="{handleSubmit}">
        <b-overlay :show="loading">
          <b-form @submit.prevent="handleSubmit(doSubmit)">
            <div class="form-underlying card-body p-3">
              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                        <h2>S</h2>
                      </div>
                      <strong class="ml-2">Situation</strong>
                    </div>
                  </a>
                </div>
                <div class="wrap_line collapse show" id="formIntra1">
                  <table class="table table-bordered">
                    <tr>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>Nama</h4>
                          <p>{{row.ap_fullname||"-"}}</p>
                        </div>
                      </td>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>No. Rekam Medis</h4>
                          <p>{{row.ap_code||"-"}}</p>
                        </div>
                      </td>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>NIK</h4>
                          <p>{{row.ap_nik||"-"}}</p>
                        </div>
                      </td>
                    </tr>
    
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Lahir</h4>
                          <p v-if="row.ap_dob">{{row.ap_dob | moment("DD MMMM YYYY")}}</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>DPJP</h4>
                          <p>{{row.dpjp_name||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Datang <span class="text-danger">*</span> </h4>
                          <b-form-group class="mb-0">
                            <date-picker v-model="row.artipr_tanggal_datang" type="datetime" value-type="YYYY-MM-DD HH:mm" placeholder="-- Tanggal Datang --" :show-second="false"></date-picker>
                          </b-form-group>
                          <VValidate name="Tanggal Datang" v-model="row.artipr_tanggal_datang" rules="required" />
                        </div>
                      </td>
                    </tr>
    
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Diagnosa</h4>
                          <p>{{row.artipr_diagnosa||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Kondisi Saat Ini <span class="text-danger">*</span></h4>
                          <b-form-group>
                            <b-form-textarea v-model="row.artipr_kondisi_saat_ini"></b-form-textarea>
                            <VValidate name="Kondisi Saat Ini" v-model="row.artipr_kondisi_saat_ini" rules="required|max:256" />
                          </b-form-group>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4></h4>
                          <p></p>
                        </div>
                      </td>
                    </tr>
    
                    <tr>
                      <td>
                        <div class="result_tab">
                        <h4>Dari Ruang</h4>
                        <p>{{row.ruang_dari||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Ke Ruang</h4>
                          <p>{{row.ruang_ke||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Pindah <span class="text-danger">*</span></h4>
                          <b-form-group class="mb-0">
                            <date-picker v-model="row.artipr_tanggal_pindah" type="datetime" value-type="YYYY-MM-DD HH:mm" placeholder="-- Tanggal Pindah --" :show-second="false"></date-picker>
                          </b-form-group>
                          <VValidate name="Tanggal Pindah" v-model="row.artipr_tanggal_pindah" rules="required" />
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                          <h2>B</h2>
                      </div>
                      <strong class="ml-2">Background</strong>
                    </div>
                  </a>
                </div>
                <div class="wrap_line collapse show" id="formIntra2">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="">Keluhan Utama <span class="text-danger">*</span></label>
                            <b-form-textarea v-model="row.artipr_keluhan_utama" name="" id="" rows="3" class="form-control" />
                            <VValidate name="Keluhan Utama" v-model="row.artipr_keluhan_utama" :rules="mrValidation.artipr_keluhan_utama"/>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="">Riwayat Penyakit</label>
                            <b-form-textarea v-model="row.artipr_riwayat_penyakit" name="" id="" rows="3" class="form-control" value="Obat: Amoxicilin, Amlodipin. Lainnya: Dingin" />
                              
                            <VValidate 
                              name="Riwayat Penyakit" 
                              v-model="row.artipr_riwayat_penyakit" 
                              :rules="mrValidation.artipr_riwayat_penyakit"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="">Intervensi yang Telah Dilakukan</label>
                            <b-form-textarea v-model="row.artipr_background_intervensi" name="" id="" cols="30" rows="3" class="form-control" />
                            
                            <VValidate 
                              name="Intervensi yang Telah Dilakukan" 
                              v-model="row.artipr_background_intervensi" 
                              :rules="mrValidation.artipr_background_intervensi"
                            />
                          </div>  
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="asuhanPembedahan">Riwayat Pembedahan </label>
                            <b-form-textarea v-model="row.artipr_riwayat_pembedahan" name="asuhanPembedahan" id="asuhanPembedahan" rows="3" class="form-control form-control-sm" />
                            
                            <VValidate 
                              name="Riwayat Pembedahan" 
                              v-model="row.artipr_riwayat_pembedahan" 
                              :rules="mrValidation.artipr_riwayat_pembedahan"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="">Obat-obatan yang Diberikan</label>
                            <b-form-textarea v-model="row.artipr_obat_obatan_yang_diberikan" name="" id="" rows="3" class="form-control" />
                            
                            <VValidate 
                              name="Obat-obatan yang Diberikan" 
                              v-model="row.artipr_obat_obatan_yang_diberikan" 
                              :rules="mrValidation.artipr_obat_obatan_yang_diberikan"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="">Cairan Parenteral yang Diberikan</label>
                            <b-form-textarea v-model="row.artipr_cairan_parental_yang_diberikan" name="" id="" rows="3" class="form-control" />
                            
                            <VValidate 
                              name="Cairan Parenteral yang Diberikan" 
                              v-model="row.artipr_cairan_parental_yang_diberikan" 
                              :rules="mrValidation.artipr_cairan_parental_yang_diberikan"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="">Riwayat Alergi</label>
                            <b-form-textarea v-model="row.artipr_riwayat_alergi" name="" id="" rows="3" class="form-control" value="Obat: Amoxicilin, Amlodipin. Lainnya: Dingin" />
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Tindakan Invasif</label>
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th width="48">#</th>
                              <th>Tindakan</th>
                              <th>Aksi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (row.artipr_tindakan_invasif||[])" :key="k">
                              <td>{{k+1}}</td>
                              <td>
                                <input type="text" v-model="row.artipr_tindakan_invasif[k]" class="form-control form-control-sm">
                                <VValidate :name="'Tindakan Invasif '+(k+1)"  v-model="row.artipr_tindakan_invasif[k]" :rules="{required : 1}"/>
                              </td>
                              <td>
                                <a href="javascript:;" class="list-icons-item"
                                  @click="row.artipr_tindakan_invasif.splice(k,1)"
                                  data-toggle="tooltip" data-placement="top" title="Delete">
                                  <i class="icon-bin"></i>
                                </a>
                              </td>
                            </tr>

                            <tr v-if="!(row.artipr_tindakan_invasif||[]).length">
                              <td colspan="3" class="text-center">
                                  Tidak ada Data
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="3" class="text-center">
                                  <a href="javascript:;" @click="addTindakan" class="btn btn-outline-primary">
                                    <i class="icon-plus2 mr-2"></i>Tambah Tindakan
                                  </a>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                        <h2>A</h2>
                      </div>
                      <strong class="ml-2">Assessment</strong>
                    </div>
                  </a>
                </div>

                <div class="wrap_line collapse show" id="formIntra3">
                  <div class="card border shadow-0">
                    <div class="bg-light card-header py-2">
                      <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                    </div>
                    <div class="card-body">
                      <table class="table table-bordered">
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Skala Nyeri</h4>
                              <p>{{row.artipr_skala_nyeri_value||0}} 
                                <span v-if="row.artipr_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                <span v-else-if="row.artipr_skala_nyeri_value >= 1 && row.artipr_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                                <span v-else-if="row.artipr_skala_nyeri_value >= 4 && row.artipr_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                <br />(Numeric Rating Scale)
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tingkat Kesadaran <span class="text-danger">*</span></h4>
                              <b-form-group>
                                <b-form-input v-model="row.artipr_tingkat_kesadaran_value" type="number"></b-form-input>
                                <VValidate name="Tingkat Kesadaran" v-model="row.artipr_tingkat_kesadaran_value" rules="required|min_value:0" />
                              </b-form-group>
                              <p>
                                {{ row.artipr_tingkat_kesadaran_value }}
                                <template v-if="row.umur_hari <= 28">
                                  <span v-if="row.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 12 && row.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 10 && row.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 7 && row.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 5 && row.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 4 && row.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </template>
                                <template v-else-if="row.umur_hari > 28">
                                  <span v-if="row.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 12 && row.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 10 && row.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 7 && row.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 5 && row.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else-if="row.artipr_tingkat_kesadaran_value >= 4 && row.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                  <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </template>
                                <br />(GCS)
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Risiko Jatuh</h4>
                              <p>{{row.artipr_resiko_jatuh_value||0}} 
                                <span v-if="row.artipr_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                <span v-else-if="row.artipr_resiko_jatuh_value >= 7 && row.artipr_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                <span v-else-if="row.artipr_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                <br />
                                  <span v-if="row.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                                  <span v-else-if="row.ap_usia > 18 && row.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                                  <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tekanan Darah</h4>
                              <p>{{row.artipr_tekanan_darah_min||"-"}}/{{row.artipr_tekanan_darah_max||"-"}} mmHG</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Nadi</h4>
                              <p>{{row.artipr_nadi||"-"}}x per menit</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Suhu</h4>
                              <p>{{row.artipr_suhu||"-"}} &deg;C</p>
                              </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Respiration Rate</h4>
                              <p>{{row.artipr_respiration||"-"}}x per menit</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>SpO2</h4>
                              <p>{{row.artipr_spo2||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4></h4>
                              <p></p>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                          <label for="">Pemeriksaan Lab</label>
                          <b-form-textarea v-model="row.artipr_lab" name="" id="" rows="3" class="form-control" />
                          <VValidate name="Pemeriksaan Lab" v-model="row.artipr_lab" :rules="mrValidation.artipr_lab" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">
                          <label for="">Radiologi</label>
                          <b-form-textarea v-model="row.artipr_radiologi" name="" id="" rows="3" class="form-control" />
                          <VValidate name="Radiologi" v-model="row.artipr_radiologi" :rules="mrValidation.artipr_riwayat_alergi" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                        <h2>R</h2>
                      </div>
                      <strong class="ml-2">Recommendation</strong>
                    </div>
                  </a>
                </div>
                <div class="wrap_line collapse show" id="formIntra4">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Tindakan yang Perlu Dilanjutkan</label>
                        <b-form-textarea v-model="row.artipr_tindakan" name="" id="" rows="3" class="form-control" />
                        <VValidate 
                          name="Tindakan yang Perlu Dilanjutkan" 
                          v-model="row.artipr_tindakan" 
                          :rules="mrValidation.artipr_tindakan"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Usulan Kolaborasi</label>
                        <b-form-textarea v-model="row.artipr_usulan_kolab" name="" id="" rows="3" class="form-control" />
                        <VValidate 
                          name="Usulan Kolaborasi" 
                          v-model="row.artipr_usulan_kolab" 
                          :rules="mrValidation.artipr_usulan_kolab"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-right">
                  <b-button variant="secondary" @click="$router.back()" type="button" class="mr-2">Kembali</b-button>
                  <b-button variant="primary" type="submit">Simpan</b-button>
                </div> 
              </div>

            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </div>
    
  </div>
</template>
<script>
import Gen from '@/libs/Gen.js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const _ = global._

export default {
  components: {
    DatePicker
  },
  data(){
    return {
      row: {},
      loading: false,
      mrValidation: {}
    }
  },
  mounted(){
    this.getForm()
  },
  methods: {
    doSubmit(){
      this.$refs.VForm.validate().then(success => {
        if(!success) return

        this.$parent.loadingOverlay = true

        Gen.apiRest('/do/' + this.$route.name, {
          data: {
            type: 'submit-serah-terima',
            payload: this.row,
            id: this.$route.params.id
          }
        }).then(res => {
          this.$parent.loadingOverlay = false

          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.$router.push({name: this.$route.name, params: {pageSlug: this.$route.params.pageSlug}})
          })
        }).catch(err => {
          this.$parent.loadingOverlay = false
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Terjadi suatu kesalahan!'
          })
        })
      })
    },
    getForm(){
      this.loading = true

      Gen.apiRest("/get/" + this.$route.name + '/form', {
        params: { id: this.$route.params.id }
      }).then(res => {
        console.log(res.data.row)
        this.$set(this, 'row', res.data.row)
        this.$set(this, 'mrValidation', res.data.mrValidation)
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$swal({
          icon: 'error',
          text: err.response.data.message || 'Terjadi suatu kesalahan!'
        })
      })
    },
    addTindakan(){
      if(!Array.isArray(this.row.artipr_tindakan_invasif)){
        this.$set(this.row, 'artipr_tindakan_invasif', [])
      }
      this.row.artipr_tindakan_invasif.push('')
    },
    hitSaveDraft(){
      return Gen.apiRest('/do/' + this.$route.name, {
        data: {
          type: 'auto-save',
          payload: this.row,
          id: this.$route.params.id
        }
      })
    },
    autoSave: _.debounce(function(){
      this.hitSaveDraft()
    }, 1000),
  },
  watch: {
    row: {
      handler(){
        this.autoSave()
      },
      deep: true
    }
  }
}
</script>