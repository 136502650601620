<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

    <div v-if="!this.$route.params.form">
      <div class="content">
        <div class="page-title pt-0 pb-3">
          <a href="javascript:;" @click="$router.push({ name: 'RanapPasienPerawatan' })" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Serah Terima Pasien</h5>
                  <div>
                    <b-button @click="getList()" v-b-tooltip.hover.noninteractive="'Muat ulang'" variant="primary">
                      <i class="icon-spinner11"></i>
                    </b-button>
                  </div>
                </div>
              </div>
              <b-table-simple class="table-sm patient-table" bordered>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Dari</th>
                    <th>Ke</th>
                    <th>Tanggal Pindah</th>
                    <th>Perawat</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="loading.coreData">
                    <tr>
                      <td colspan="7" class="text-center">Loading...</td>
                    </tr>
                  </template>
                  <template v-else-if="data.length < 1">
                    <tr>
                      <td colspan="7" class="text-center">Tidak Terdapat Data.</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(value, index) in data" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td><strong>{{ value.ruang_dari }}</strong></td>
                      <td><strong>{{ value.ruang_ke }}</strong></td>
                      <td>
                        <template v-if="value.tanggal_pindah">{{ value.tanggal_pindah | moment('DD MMMM YYYY, HH:mm') }}</template>
                        <template v-else>-</template>
                      </td>
                      <td>
                        {{ value.perawat || '-' }}
                      </td>
                      <template v-if="value.type == 'RANAP_TO_RANAP'">
                        <td>
                          <b-badge v-if="value.artipr_status == 'N'" variant="warning">Belum Diisi</b-badge>
                          <b-badge v-else-if="value.artipr_status == 'T'" variant="primary">TTD Dokumen</b-badge>
                          <b-badge v-else-if="value.artipr_status == 'D'" variant="success">Selesai</b-badge>
                        </td>
                        <td>
                          <router-link :to="{name: $route.name, params: {pageSlug: pageSlug, form: 'form', id: value.artipr_id}}" v-if="moduleRole('form_intra') && (value.artipr_status == 'N' || value.artipr_status == 'T')" class="mr-1 btn btn-icon alpha-blue border-blue" v-b-tooltip.hover.noninteractive="'Isi Form Intra'"><i class="icon-pencil"></i></router-link>
                          <router-link :to="{name: $route.name, params: {pageSlug: pageSlug, form: 'detail', id: value.artipr_id}}" v-if="value.artipr_status == 'D'" class="mr-1 btn btn-icon alpha-blue border-blue" v-b-tooltip.hover.noninteractive="'Detail Form Intra'"><i class="icon-eye"></i></router-link>
                          <a href="javascript:;" v-if="value.artipr_status == 'T' && moduleRole('ttd_intra')" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Tanda Tangan'" @click.prevent="openTTD(value.artipr_id)">
                            <i class="fa-solid fa-file-signature"></i>
                          </a>
                        </td>
                      </template>
                      <template v-else-if="value.type == 'RANAP_TO_BEDAH'">
                        <td>
                          <b-badge variant="success">Selesai</b-badge>
                        </td>
                        <td>
                          <a href="javascript:;" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Download File'" @click.prevent="downloadFile(value)">
                            <i class="fa-solid fa-download"></i>
                          </a>
                        </td>
                      </template>
                      <template v-else-if="value.type == 'BEDAH_TO_RANAP'">
                        <td>
                          <b-badge v-if="!value.arantbr_is_draft || value.arantbr_is_draft == 'Y'" variant="warning">Isi Formulir</b-badge>
                          <b-badge v-else-if="value.arantbr_is_draft == 'N' && !value.arantbr_ttd_by" variant="primary">TTD Dokumen</b-badge>
                          <b-badge v-else-if="value.arantbr_is_draft == 'N' && value.arantbr_ttd_by" variant="success">Selesai</b-badge>
                        </td>
                        <td>
                          <router-link :to="{name: $route.name, params: {pageSlug: pageSlug, form: 'form-bedah', id: value.arano_id}, query: {getByID: 1, arantbr_id: value.arantbr_id}}" v-if="moduleRole('form_intra') && (!value.arantbr_is_draft || value.arantbr_is_draft == 'Y' || !value.arantbr_ttd_by)" class="mr-1 btn btn-icon alpha-blue border-blue" v-b-tooltip.hover.noninteractive="'Isi Form Intra'"><i class="icon-pencil"></i></router-link>

                          <!--  
                          -->
                          <a href="javascript:;" v-if="value.arantbr_is_draft == 'N' && !value.arantbr_ttd_by && moduleRole('ttd_intra')" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Tanda Tangan'" @click.prevent="TTDBedahRanap({arantbr_id: value.arantbr_id, arano_id: value.arano_id})">
                            <i class="fa-solid fa-file-signature"></i>
                          </a>

                          <a v-if="value.arantbr_ttd_by" href="javascript:;" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Download File'" @click.prevent="downloadFileBedahRanap(value)">
                            <i class="fa-solid fa-download"></i>
                          </a>
                        </td>
                      </template>
                      <template v-else-if="value.type == 'RANAP_TO_RADIOLOGI'">
                        <td>
                          <b-badge variant="success">Selesai</b-badge>
                        </td>
                        <td>
                          <a href="javascript:;" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Download File'" @click.prevent="downloadFileRanapRadiologi(value)">
                            <i class="fa-solid fa-download"></i>
                          </a>
                        </td>
                      </template>
                      <template v-else-if="value.type == 'TO_RANAP'">
                        <td>
                          <b-badge variant="success">Selesai</b-badge>
                        </td>
                        <td>
                          <a href="javascript:;" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover.noninteractive="'Download File'" @click.prevent="downloadFileRanapUGDPOLI(value)">
                            <i class="fa-solid fa-download"></i>
                          </a>
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </b-table-simple>
            </div>
          </div>
        </div>
      </div>
      <b-modal size="xl" title="Tanda Tangan Form Transfer Intra" id="ttd_transfer_intra" hide-footer>
        <b-overlay :show="loading.modal">
          <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(doSubmitTTD)">  
              <div class="form-underlying card-body p-3">
                <div class="card mb-2">
                  <div class="card-header p-0" role="tab">
                    <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                      <div class="d-flex align-items-center">
                        <div class="label_code m-0">
                          <h2>S</h2>
                        </div>
                        <strong class="ml-2">Situation</strong>
                      </div>
                    </a>
                  </div>
                  <div class="wrap_line collapse show" id="formIntra1">
                    <table class="table table-bordered">
                      <tr>
                        <td width="33%">
                          <div class="result_tab">
                            <h4>Nama</h4>
                            <p>{{dataModal.ap_fullname||"-"}}</p>
                          </div>
                        </td>
                        <td width="33%">
                          <div class="result_tab">
                            <h4>No. Rekam Medis</h4>
                            <p>{{dataModal.ap_code||"-"}}</p>
                          </div>
                        </td>
                        <td width="33%">
                          <div class="result_tab">
                            <h4>NIK</h4>
                            <p>{{dataModal.ap_nik||"-"}}</p>
                          </div>
                        </td>
                      </tr>
      
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Tanggal Lahir</h4>
                            <p v-if="dataModal.ap_dob">{{dataModal.ap_dob | moment("DD MMMM YYYY")}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>DPJP</h4>
                            <p>{{dataModal.dpjp_name||"-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Tanggal Datang</h4>
                            {{ dataModal.artipr_tanggal_datang | moment('DD MMMM YYYY, HH:mm') }}
                          </div>
                        </td>
                      </tr>
      
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Diagnosa</h4>
                            <p>{{dataModal.artipr_diagnosa||"-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Kondisi Saat Ini</h4>
                            {{ dataModal.artipr_kondisi_saat_ini }}
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4></h4>
                            <p></p>
                          </div>
                        </td>
                      </tr>
      
                      <tr>
                        <td>
                          <div class="result_tab">
                          <h4>Dari Ruang</h4>
                          <p>{{dataModal.ruang_dari||"-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Ke Ruang</h4>
                            <p>{{dataModal.ruang_ke||"-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Tanggal Pindah</h4>
                            {{ dataModal.artipr_tanggal_pindah | moment('DD MMMM YYYY, HH:mm')}}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="card mb-2">
                  <div class="card-header p-0" role="tab">
                    <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                      <div class="d-flex align-items-center">
                        <div class="label_code m-0">
                            <h2>B</h2>
                        </div>
                        <strong class="ml-2">Background</strong>
                      </div>
                    </a>
                  </div>
                  <div class="wrap_line collapse show" id="formIntra2">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for=""><strong>Keluhan Utama</strong></label>
                              <p>{{ dataModal.artipr_keluhan_utama }}</p>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label for=""><strong>Riwayat Penyakit</strong></label>
                              <p>{{ dataModal.artipr_riwayat_penyakit }}</p>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label for=""><strong>Intervensi yang Telah Dilakukan</strong></label>
                              <p>{{ dataModal.artipr_background_intervensi }}</p>
                            </div>  
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="asuhanPembedahan">Riwayat Pembedahan </label>
                              <P>{{ dataModal.artipr_riwayat_pembedahan }}</P>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label for=""><strong>Obat-obatan yang Diberikan</strong></label>
                              <p>{{ dataModal.artipr_obat_obatan_yang_diberikan }}</p>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label for=""><strong>Cairan Parenteral yang Diberikan</strong></label>
                              <p>{{ dataModal.artipr_cairan_parental_yang_diberikan }}</p>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label for=""><strong>Riwayat Alergi</strong></label>
                              <p>{{ dataModal.artipr_riwayat_alergi }}</p>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for=""><strong>Tindakan Invasif</strong></label>
                          <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th width="48">#</th>
                                <th>Tindakan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (dataModal.artipr_tindakan_invasif||[])" :key="k">
                                <td>{{k+1}}</td>
                                <td>
                                  {{ dataModal.artipr_tindakan_invasif[k] }}
                                </td>
                              </tr>
                              <tr v-if="!(dataModal.artipr_tindakan_invasif||[]).length">
                                <td colspan="3" class="text-center">
                                    Tidak ada Data
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-2">
                  <div class="card-header p-0" role="tab">
                    <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                      <div class="d-flex align-items-center">
                        <div class="label_code m-0">
                          <h2>A</h2>
                        </div>
                        <strong class="ml-2">Assessment</strong>
                      </div>
                    </a>
                  </div>

                  <div class="wrap_line collapse show" id="formIntra3">
                    <div class="card border shadow-0">
                      <div class="bg-light card-header py-2">
                        <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                      </div>
                      <div class="card-body">
                        <table class="table table-bordered">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Skala Nyeri</h4>
                                <p>{{dataModal.artipr_skala_nyeri_value||0}} 
                                  <span v-if="dataModal.artipr_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                  <span v-else-if="dataModal.artipr_skala_nyeri_value >= 1 && dataModal.artipr_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                                  <span v-else-if="dataModal.artipr_skala_nyeri_value >= 4 && dataModal.artipr_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                  <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                  <br />(Numeric Rating Scale)
                                </p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Tingkat Kesadaran</h4>
                                {{ dataModal.artipr_tingkat_kesadaran_value }}
                                <p>
                                  {{ dataModal.artipr_tingkat_kesadaran_value }}
                                  <template v-if="dataModal.umur_hari <= 28">
                                    <span v-if="dataModal.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 12 && dataModal.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 10 && dataModal.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 7 && dataModal.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 5 && dataModal.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 4 && dataModal.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                  </template>
                                  <template v-else-if="dataModal.umur_hari > 28">
                                    <span v-if="dataModal.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 12 && dataModal.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 10 && dataModal.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 7 && dataModal.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 5 && dataModal.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 4 && dataModal.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                  </template>
                                  <br />(GCS)
                                </p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Risiko Jatuh</h4>
                                <p>{{dataModal.artipr_resiko_jatuh_value||0}} 
                                  <span v-if="dataModal.artipr_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                  <span v-else-if="dataModal.artipr_resiko_jatuh_value >= 7 && dataModal.artipr_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                  <span v-else-if="dataModal.artipr_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                  <br />
                                  <span v-if="dataModal.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                                  <span v-else-if="dataModal.ap_usia > 18 && dataModal.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                                  <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Tekanan Darah</h4>
                                <p>{{dataModal.artipr_tekanan_darah_min||"-"}}/{{dataModal.artipr_tekanan_darah_max||"-"}} mmHG</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Nadi</h4>
                                <p>{{dataModal.artipr_nadi||"-"}}x per menit</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Suhu</h4>
                                <p>{{dataModal.artipr_suhu||"-"}} &deg;C</p>
                                </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Respiration Rate</h4>
                                <p>{{dataModal.artipr_respiration||"-"}}x per menit</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>SpO2</h4>
                                <p>{{dataModal.artipr_spo2||"-"}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4></h4>
                                <p></p>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <div class="form-group">
                            <label for=""><strong>Pemeriksaan Lab</strong></label>
                            <p>{{ dataModal.artipr_lab }}</p>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                            <label for=""><strong>Radiologi</strong></label>
                            <p>{{ dataModal.artipr_radiologi }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-2">
                  <div class="card-header p-0" role="tab">
                    <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                      <div class="d-flex align-items-center">
                        <div class="label_code m-0">
                          <h2>R</h2>
                        </div>
                        <strong class="ml-2">Recommendation</strong>
                      </div>
                    </a>
                  </div>
                  <div class="wrap_line collapse show" id="formIntra4">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Tindakan yang Perlu Dilanjutkan</label>
                          <p>{{ dataModal.artipr_tindakan }}</p>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Usulan Kolaborasi</label>
                          <p>{{ dataModal.artipr_usulan_kolab }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-2">
                  <b-row>
                    <b-col md="4">
                      <b-form-group>
                        <label><strong>Penandatangan 1</strong></label>
                        <b-form-input v-model="dataModal.artipr_ttd_name_1"></b-form-input>
                        <VValidate name="Nama Penandatangan" v-model="dataModal.artipr_ttd_name_1" :vid="'nama_ttd' + 1" rules="required" />
                      </b-form-group>
                      <div class="form-group">
                        <div class="signing-element" data-target-id="ttd1">
                          <VueSignaturePad  
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttd1"
                          />
                          <div class="mt-2">
                            <button type="button" @click="undoTTD('ttd1','artipr_ttd_1')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                              <b><i class="icon-cross3"></i></b>
                              <span>Clear</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <label><strong>Penandatangan 2</strong></label>
                      <b-form-group>
                        <b-form-input v-model="dataModal.artipr_ttd_name_2"></b-form-input>
                        <VValidate name="Nama Penandatangan" v-model="dataModal.artipr_ttd_name_2" :vid="'nama_ttd' + 2" rules="required" />
                      </b-form-group>
                      <div class="form-group">
                        <div class="signing-element" data-target-id="ttd2">
                          <VueSignaturePad  
                            class="sign-container"
                            width="100%"
                            height="160px"
                            ref="ttd2"
                          />
                          <div class="mt-2">
                            <button type="button" @click="undoTTD('ttd2','artipr_ttd_2')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                              <b><i class="icon-cross3"></i></b>
                              <span>Clear</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-row class="mt-3">
                  <b-col md=12 class="text-right">
                    <b-button type="button" class="mr-2" variant="secondary" @click="closeModalTTD">Tutup</b-button>
                    <b-button type="button" variant="primary" @click="doSubmitTTD">Simpan</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>

      <b-modal id="ttd_bedah_ranap" size="xl" title="Tanda Tangan Form Bedah Ke Ranap" hide-footer>
        <ModalTTDBedahRanap v-if="dataModal.arantbr_id" :arano_id="dataModal.arano_id" :arantbr_id="dataModal.arantbr_id" @closeModal="closeModalTTDBedahRanap" @successSubmit="successTTDBedahRanap" @resize="resize" />
      </b-modal>

    </div>
    <Form v-else-if="this.$route.params.form == 'form'"/>
    <FormTransferBedah v-else-if="this.$route.params.form == 'form-bedah'"/>
    <Detail v-else-if="this.$route.params.form == 'detail'"/>
  </div>
</template>

<script>
import $ from 'jquery'
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Form from './Form.vue'
import FormTransferBedah from './FormTransferBedah.vue'
import ModalTTDBedahRanap from './ModalTTDBedahRanap.vue'
import Detail from './Detail.vue'

const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components: {
    PatientInfo,
    Form,
    Detail,
    FormTransferBedah,
    ModalTTDBedahRanap
  },
  data(){
    return {
      loading: {
        patientInfo: false,
        coreData: false,
        modal: false,
      },
      patientData: {},
      data: [],
      dataModal: {}
    }
  },
  mounted(){
    this.getPatientInfo()

    if(!this.$route.params.type){
      this.getList()
    }

  },

  methods: {
    closeModalTTDBedahRanap(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.hide('ttd_bedah_ranap')
    },
    TTDBedahRanap(val){
      this.$set(this.dataModal, 'arantbr_id', val.arantbr_id)
      this.$set(this.dataModal, 'arano_id', val.arano_id)
      this.$bvModal.show('ttd_bedah_ranap')
    },
    successTTDBedahRanap(){
      this.closeModalTTDBedahRanap()
      this.getList()
    },
    downloadFile(val){
        let data = {id: val.arano_id}
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/dokumen-ttd-ranap/${'DokumenTranferIntraBedah'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `Transfer Intra - ${this.patientData.ap_fullname} - ${this.patientData.ap_code}.pdf`
                link.click()
            },
            fail: () => {
                self.loadingOverlay = false
                const Toast = self.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                })
                Toast.fire({
                  icon: "error",
                  title: "Failed to download file"
                })
            }
        })
    },
    downloadFileBedahRanap(val){
        let data = {id: val.arantbr_id}
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/dokumen-ttd-ranap/${'DokumenTranferIntraBedahRanap'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `Transfer Intra Bedah Ranap - ${this.patientData.ap_fullname} - ${this.patientData.ap_code}.pdf`
                link.click()
            },
            fail: () => {
                self.loadingOverlay = false
                const Toast = self.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                })
                Toast.fire({
                  icon: "error",
                  title: "Failed to download file"
                })
            }
        })
    },
    downloadFileRanapRadiologi(val){
        let data = {id: val.arantr_id}
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/dokumen-ttd-ranap/${'DokumenTranferIntraRadiologi'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `Transfer Intra Radiologi - ${this.patientData.ap_fullname} - ${this.patientData.ap_code}.pdf`
                link.click()
            },
            fail: () => {
                self.loadingOverlay = false
                const Toast = self.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                })
                Toast.fire({
                  icon: "error",
                  title: "Failed to download file"
                })
            }
        })
    },
    downloadFileRanapUGDPOLI(val){
        let data = {id: val.aranres_id}
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/dokumen-ttd-ranap/${'DokumenTranferIntraUGDPOLI'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `Transfer Intra Ke Ranap - ${this.patientData.ap_fullname} - ${this.patientData.ap_code}.pdf`
                link.click()
            },
            fail: () => {
                self.loadingOverlay = false
                const Toast = self.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                })
                Toast.fire({
                  icon: "error",
                  title: "Failed to download file"
                })
            }
        })
    },
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.dataModal[field] = null
    },
    output(refs,field) {
      try{
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        console.log(field, isEmpty)
        if(!isEmpty){
          this.dataModal[field] = data
        }
      }catch(err){
        console.log(err)
      }
    },
    resize(){
      setTimeout(()=>{
        console.log('resize')
        $(".sign-container canvas").attr("width",339)
        $(".sign-container canvas").attr("height",160)
      },200)
    },
    openTTD(id){
      this.$bvModal.show('ttd_transfer_intra')
      this.loading.modal = true
      this.resize()

      Gen.apiRest('/get/' + this.$route.name + '/form-ttd', {
        params: {
          id
        }
      }).then(res => {
        this.$set(this, 'dataModal', res.data.row)
        this.loading.modal = false
      }).catch(() => {
        this.loading.modal = false
      })
    },
    closeModalTTD(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.hide('ttd_transfer_intra')
    },
    getPatientInfo(){
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/"+'RanapPasienPerawatan',
        { 
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'patientInfo', false)
      })
    },
    getList(){
      this.$set(this.loading, 'coreData', true)

      Gen.apiRest(
        "/get/" + this.$route.name,
        {
          params: {
            id_registrasi: this.pageSlug,
            page: this.filter.page || 1,
            shown: this.filter.shown
          }
        }
      ).then(res => {
        this.$set(this, 'data', res.data.data)
        this.$set(this.loading, 'coreData', false)
      }).catch(err => {
        this.$set(this.loading, 'coreData', false)
      })
    },
    doSubmitTTD(){
      this.output('ttd1','artipr_ttd_1')
      this.output('ttd2','artipr_ttd_2')

      if(!this.dataModal.artipr_ttd_1 || !this.dataModal.artipr_ttd_2){
        return this.$swal({
          icon: 'error',
          title: 'Mohon Isi Tanda Tangan'
        })
      }

      this.$refs.VFormTTD.validate().then(success => {
        if(!success) return

        this.loadingOverlay = true

        Gen.apiRest('/do/' + this.$route.name, {
          data: {
            type: 'submit-ttd',
            ...this.dataModal
          }
        }).then(res => {
          this.loadingOverlay = false
          this.$swal({
            icon: 'success',
            title: res.data.message,
          }).then(() => {
            this.closeModalTTD()
            this.getList()
          })
        }).catch(err => {
          this.loadingOverlay = false
          this.$swal({
            icon: 'error',
            message: err.response.data.message || 'Terjadi suatu kesalahan!'
          })
        })
      })
    }
  },
  watch: {
    $route() {
      this.getPatientInfo()
      if(!this.$route.params.type){
        this.getList()
      }
    }
  },
}
</script>